import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const MockButtons = () => {
  const Button = ({ type, children }) =>
    <p
      style={{ cursor: 'pointer' }}
      onClick={() => mock(`${type}@example.com`)}
    >
      { children }
    </p>

  Button.propTypes = {
    type: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
  }

  const mock = (username) => {
    document.getElementById('username').value = username
    document.getElementById('password').value = 'cl3'
  }

  const mocks = ['active', 'in_freeze', 'in_cancellation', 'in_trial', 'presale', 'cancelled', 'cancelled_in_presale', 'cancelled_in_trial', 'used_freeze', 'scheduled_freeze', 'sepa_payment']

  return (
    <div style={{ marginTop: '100px', fontSize: '12px' }}>
      <h2>Fill in mock users</h2>
      <p>
        Click on a status/attribute below to fill in the login form with credentials for mock members with different types of statuses
      </p>
      { mocks.map(m => <Button key={m} type={m}>{m}</Button>) }
    </div>
  )
}

const LoginForm = ({ checkCredentials, setError, t }) => {
  const username = React.createRef()
  const password = React.createRef()

  const getCredentials = () => (
    {
      username: username.current.value,
      password: password.current.value,
    }
  )

  const onSubmit = (e) => {
    e.preventDefault()
    checkCredentials(getCredentials())
      .catch(setError)
  }

  return (
    <>
      <form id='login-form' onSubmit={onSubmit}>
        <label>
          { t('forms.labels.username') }:
          <input
            id='username'
            type='text'
            name='username'
            autoCorrect='off'
            autoCapitalize='none'
            ref={username}
          />
        </label>
        <label>
          { t('forms.labels.password') }:
          <input
            id='password'
            type='password'
            name='password'
            ref={password}
          />
        </label>
        <Link to={'/password/reset'}>
          { t('forms.login.forgotten') }
        </Link>
        <button type='submit'>
          { t('forms.login.button') }
        </button>
      </form>
      { process.env.REACT_APP_DEMO &&
        <MockButtons/>
      }
    </>
  )
}

LoginForm.propTypes = {
  checkCredentials: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default LoginForm
