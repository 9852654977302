import React from 'react'
import PropTypes from 'prop-types'
import i18n from '../i18n'
import { GlobalContext } from 'components/context'

const LocaleButton = ({ onClick, language, selected }) => {
  const style = {
    marginRight: '10px',
    fontWeight: selected ? 'bold' : '',
  }

  return (
    <button
      className={'link-button'}
      onClick={onClick}
      style={style}
    >
      { language }
    </button>
  )
}

LocaleButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
}

const LocaleSwitcher = () => {
  const { locale, setLocale, locales } = React.useContext(GlobalContext)

  React.useEffect(() => {
    i18n.changeLanguage(locale)
  }, [locale])

  // Mainly for dev, so we can distinguish between same language for different countries
  const localeNames = () => {
    const counts = {}
    locales.forEach(l => { counts[l.substring(0, 2)] ? counts[l.substring(0, 2)] += 1 : counts[l.substring(0, 2)] = 1 })
    return locales.map(l => counts[l.substring(0, 2)] > 1 ? { label: l, name: l } : { label: l.substring(0, 2), name: l })
  }

  return (
    <span style={{ float: 'right' }}>
      {
        localeNames().map((l) =>
          <LocaleButton
            key={l.name}
            language={l.label}
            onClick={() => setLocale(l.name)}
            selected={locale === l.name}
          />,
        )
      }
    </span>
  )
}

export default LocaleSwitcher
