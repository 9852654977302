import React from 'react'
import PropTypes from 'prop-types'
import { Page, Text, View, Image, Font, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer'
import { GlobalContext, UserContext } from 'components/context'
import { amountToCurrency, dateToLocale } from 'helpers'

const PaymentStatus = ({ invoice, t }) => {
  const color = invoice.status === 'charged' ? '#0fad69' : '#ff0034'

  const style = {
    border: `5px solid ${color}`,
    borderRadius: '6px',
    width: 256,
    height: 67,
    paddingTop: 20,
    color: color,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  }

  return (
    <View style={styles.paymentStatus}>
      <Text style={style}>
        { t(`invoice.status.${invoice.status}`) }
      </Text>
    </View>
  )
}

PaymentStatus.propTypes = {
  invoice: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

const PaymentMethod = ({ invoice, locale, t }) => {
  const { payment_method: paymentMethod } = invoice
  const acceptedPayment = invoice.payments.find(p => p.status === 'accepted')

  if (invoice.amount === 0) return null
  if (!paymentMethod || !paymentMethod.type) return null

  const date = dateToLocale(new Date(acceptedPayment.created_at), locale)

  return (
    <View style={{ position: 'absolute', bottom: 70, left: 30, textAlign: 'center', fontSize: 10 }}>
      <Text>
        { t('invoice.credit_card_info.pre') }
        <Text style={{ fontWeight: 'bold' }}>{ paymentMethod.details }</Text>
        { t('invoice.credit_card_info.post', { date: date }) }
      </Text>
    </View>
  )
}

PaymentMethod.propTypes = {
  invoice: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

const refund = (invoice) => {
  const acceptedPayment = invoice.payments.find(l => l.status === 'accepted')

  if (!acceptedPayment) return null

  const refund = acceptedPayment.refunds.find(r => r.status === 'accepted')

  if (!refund) return null

  return refund
}

const humanise = (str) => {
  return str.replace(/^[_]*(\w)/, (_, c) => c.toUpperCase()) // uppercase first letter
    .replace(/[_]+(\w)/g, (_, c) => ' ' + c.toUpperCase()) // uppercase letter after _ and replace '_' with ' ' (space)
}

const InvoiceLine = ({ invoice, line, locale, t }) => {
  let period = ''

  if (line.period && (line.period.from || line.period.to)) {
    period = `${dateToLocale(line.period.from, locale)} - ${dateToLocale(line.period.to, locale)}`
  }

  let description = ''
  const { human_concept: humanConcept } = line
  const systemConceptTranslation = t(`invoice.description.${line.system_concept}`)

  // If we have a translation, then use it, otherwise use bare human concept or fall back to bare system concept
  if (systemConceptTranslation !== ' ' && systemConceptTranslation !== `invoice.description.${line.system_concept}`) {
    description = systemConceptTranslation
  } else if (humanConcept && humanConcept.length > 1 && humanConcept !== 'tbd') {
    description = line.human_concept
  } else {
    description = humanise(line.system_concept)
  }

  return (
    <View style={{ flexDirection: 'row' }}>
      <Text style={styles.invoiceLines.period}>
        { period }
      </Text>
      <Text style={styles.invoiceLines.description}>
        { description }
      </Text>
      <Text style={styles.invoiceLines.amount}>
        { amountToCurrency(line.amount, locale, invoice.currency) }
      </Text>
    </View>
  )
}

InvoiceLine.propTypes = {
  invoice: PropTypes.object.isRequired,
  line: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

const OperatorInfo = ({ t }) =>
  <View style={styles.operator}>
    <Text>
      { t('invoice.operator.name') }
    </Text>
    <Text>
      { t('invoice.operator.address') }
    </Text>
    <Text>
      { t('invoice.operator.organization_number') }
    </Text>
  </View>

OperatorInfo.propTypes = {
  t: PropTypes.func.isRequired,
}

const MembershipInfo = ({ membership, t }) =>
  <View style={styles.member}>
    <Text style={styles.member.title}>
      { t('invoice.member.title') }
    </Text>
    <Text>
      { membership.profile.first_name } { membership.profile.last_name }
    </Text>
    <Text>
      { membership.profile.address.street }
    </Text>
    <Text>
      { membership.profile.address.postal_code } { membership.profile.address.postal_location }
    </Text>
  </View>

MembershipInfo.propTypes = {
  membership: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

const InvoiceNumber = ({ invoice, t }) =>
  <Text style={styles.invoiceNumber}>
    { t('invoice.invoice_number', { invoice_number: invoice.invoice_number }) }
  </Text>

InvoiceNumber.propTypes = {
  invoice: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

const InvoiceDate = ({ invoice, t }) =>
  <Text style={styles.invoiceDate}>
    { t('invoice.invoice_date', { invoice_date: invoice.invoice_date }) }
  </Text>

InvoiceDate.propTypes = {
  invoice: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

const InvoiceTableHeader = ({ t }) =>
  <View style={styles.invoiceHeader}>
    <Text style={styles.invoiceLines.period}>
      { t('invoice.titles.period')}
    </Text>
    <Text style={styles.invoiceLines.description}>
      { t('invoice.titles.description')}
    </Text>
    <Text style={styles.invoiceLines.amount}>
      { t('invoice.titles.amount')}
    </Text>
  </View>

InvoiceTableHeader.propTypes = {
  t: PropTypes.func.isRequired,
}

const InvoiceLines = ({ invoice, locale, t }) =>
  <View style={{ position: 'absolute', top: 280, left: 35, right: 35, fontSize: 10 }}>
    {
      invoice.lines.map(l => <InvoiceLine key={l.system_concept} invoice={invoice} line={l} locale={locale} t={t} />)
    }
    <Refunds invoice={invoice} locale={locale} />
    <View style={{ flexDirection: 'row', fontFamily: 'AvantGardeDemi' }}>
      <Text style={{ padding: 4, paddingRight: 8, paddingTop: 30, textAlign: 'right', fontSize: 12 }}>
        { amountToCurrency(invoice.amount, locale, invoice.currency) }
      </Text>
    </View>
    <VatLine invoice={invoice} locale={locale} t={t} />
  </View>

InvoiceLines.propTypes = {
  invoice: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

const Refunds = ({ invoice, locale }) => {
  const r = refund(invoice)

  if (!r) return null

  return (
    <View style={{ flexDirection: 'row' }}>
      <Text style={styles.invoiceLines.period}>
        { dateToLocale(new Date(r.created_at), locale) }
      </Text>
      <Text style={styles.invoiceLines.description}>
        Refunded
      </Text>
      <Text style={styles.invoiceLines.amount}>
        { amountToCurrency(r.amount, locale, invoice.currency) }
      </Text>
    </View>
  )
}

Refunds.propTypes = {
  invoice: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
}

const VatLine = ({ invoice, locale, t }) => {
  const vatRates = t('invoice.vat_rates').split(/; */).map((elem) => {
    const parts = elem.split(/: */)
    return { date: parts[0], value: parseFloat(parts[1]) }
  }).sort((a, b) => (a.date === b.date) ? 0 : ((b.date > a.date) ? 1 : -1))
  const vatRate = vatRates.find((elem) => elem.date <= invoice.invoice_date)

  if (vatRate === undefined || vatRate.value === undefined || isNaN(vatRate.value) || vatRate.value === 0.0) {
    return (<div></div>)
  }
  const vatAmount = invoice.amount * vatRate.value / 100.0

  return (
    <View style={{ flexDirection: 'row' }}>
      <Text style={styles.invoiceLines.period}>
        {}
      </Text>
      <Text style={styles.invoiceLines.description}>
        { t('invoice.description.vat', { rate: vatRate.value }) }
      </Text>
      <Text style={styles.invoiceLines.amount}>
        { amountToCurrency(vatAmount, locale, invoice.currency) }
      </Text>
    </View>
  )
}

VatLine.propTypes = {
  invoice: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

const PDFInvoice = ({ invoice, locale, membership, t, theme }) => {
  const imgSrc = `/themes/${theme}/logo.png`

  return (
    <Document width={'2000px'}>
      <Page style={styles.body}>
        <Image
          style={styles.logo}
          src={imgSrc}
        />
        <OperatorInfo t={t} />
        <MembershipInfo membership={membership} t={t} />
        <div>
          <InvoiceNumber invoice={invoice} t={t} />
          <InvoiceDate invoice={invoice} t={t} />
        </div>
        <InvoiceTableHeader t={t} />
        <InvoiceLines invoice={invoice} locale={locale} t={t} />
        <PaymentStatus invoice={invoice} t={t} />
        <PaymentMethod invoice={invoice} locale={locale} t={t} />
      </Page>
    </Document>
  )
}

PDFInvoice.propTypes = {
  invoice: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  membership: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
}

Font.register({
  family: 'AvantGarde',
  fonts: [
    { src: '/webFonts/AvantGardeGothicITCW02Bk.ttf' },
    { src: '/webFonts/AvantGardeGothicITCW01Bd.ttf', fontWeight: 'bold' },
  ],
})

Font.register({
  family: 'AvantGardeDemi',
  fonts: [
    { src: '/webFonts/ITCAvantGardeW04-Demi.ttf' },
  ],
})

const styles = StyleSheet.create({
  logo: {
    maxWidth: 120,
    marginBottom: 50,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'AvantGarde',
  },
  operator: {
    position: 'absolute',
    right: 35,
    top: 35,
    fontSize: 10,
    lineHeight: 1.4,
  },
  member: {
    fontSize: 14,
    position: 'absolute',
    top: 150,
    left: 35,
    lineHeight: 1.43,

    title: {
      fontWeight: 500,
    },
  },
  invoiceNumber: {
    textAlign: 'right',
    fontWeight: 700,
    fontSize: 14,
  },
  invoiceDate: {
    textAlign: 'right',
    fontWeight: 400,
    fontSize: 10,
    paddingTop: 2,
  },
  invoiceHeader: {
    position: 'absolute',
    top: 250,
    left: 35,
    right: 35,
    height: 22,
    backgroundColor: '#e9e9e9',
    fontSize: 10,
    fontFamily: 'AvantGardeDemi',
    flexDirection: 'row',
  },
  invoiceLines: {
    period: {
      padding: 4,
      width: 200,
    },
    description: {
      padding: 4,
      width: 300,
    },
    amount: {
      padding: 4,
      paddingRight: 10,
      justifyContent: 'right',
    },
  },
  paymentStatus: {
    position: 'absolute',
    bottom: 100,
    left: 170,
    textAlign: 'center',
  },
})

const Invoice = ({ match }) => {
  const { locale, t, theme } = React.useContext(GlobalContext)
  const { get, membership, setMembershipState } = React.useContext(UserContext)
  const [invoice, setInvoice] = React.useState(null)

  React.useEffect(() => {
    if (membership) return
    get('membership').then(res => setMembershipState(res))
  })

  React.useEffect(() => {
    if (invoice) return

    get('invoices', match.params.id).then(setInvoice).catch(err => {
      console.log('error', err)
      window.location.href = '/'
    })
  }, [get, invoice, match.params.id, setInvoice])

  if (!membership) return <div>Loading</div>
  if (!invoice) return <div>Loading</div>

  return (
    <PDFViewer width={'100%'} height={window.innerHeight}>
      <PDFInvoice t={t} membership={membership} invoice={invoice} locale={locale} theme={theme} />
    </PDFViewer>
  )
}

Invoice.propTypes = {
  match: PropTypes.object.isRequired,
}

export default Invoice
