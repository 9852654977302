import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { options, result, routes } from '../api'
import { useTranslation } from './i18n'
import Layout from './components/Layout'
import fetch from 'fetch'

const GlobalContext = React.createContext()

const operator = process.env.REACT_APP_OPERATOR

// locales is set as an env var
// e.g. REACT_APP_LANGUAGES=nb,en
const locales = process.env.REACT_APP_LANGUAGES.split(',')

const checkCredentials = (fetch, credentials) => {
  const opts = options({
    method: 'POST',
    body: {
      username: credentials.username,
      password: credentials.password,
      operator_id: operator,
    },
  })

  return fetch(routes.auth, opts).then((res) => result(res))
}

const resetPassword = (fetch, username, url, locale) => {
  const opts = options({
    method: 'POST',
    body: {
      operator_id: operator,
      username: username,
      url: url,
      locale: locale,
    },
  })

  return fetch(routes.resetPassword, opts).then((res) => result(res))
}

const updatePassword = (fetch, token, password) => {
  const opts = options({
    method: 'POST',
    body: {
      operator_id: operator,
      token: token,
      password: password,
    },
  })

  return fetch(routes.updatePassword, opts).then((res) => result(res))
}

const Translation = ({ k, t, vars }) => {
  // We want \n to actually be line breaks
  const style = {
    whiteSpace: 'pre-line',
  }

  return <span style={style}>{t(k, vars)}</span>
}

Translation.propTypes = {
  k: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  vars: PropTypes.object.isRequired,
}

Translation.defaultProps = {
  vars: {},
}

const withGlobal = (fetch) => {
  const GlobalStateComponent = (props) => {
    // Set token from search params
    const url = new URL(window.location.href)
    const accessToken = url.searchParams.get('access_token')
    if (accessToken) {
      localStorage.setItem('token', accessToken)
      // remove the token from the url to prevent sharing
      url.searchParams.delete('access_token')
      window.history.replaceState(null, '', url.toString())
    }

    const { t } = useTranslation()
    const [token, setToken] = useState(localStorage.getItem('token'))
    const [theme, setTheme] = useState(process.env.REACT_APP_THEME)

    let l = localStorage.getItem('locale')
    if (locales.indexOf(l) === -1) {
      l = locales[0]
      localStorage.setItem('locale', l)
    }
    const [locale, setLocale] = useState(l)

    const trans = (k, vars = {}) => <Translation k={k} t={t} vars={vars} />

    const ctx = {
      t,
      locale,
      token,
      theme,
      setTheme,
      locales,
      checkCredentials: (credentials) => {
        return checkCredentials(fetch, credentials).then((res) => {
          localStorage.setItem('token', res.token)
          setToken(localStorage.getItem('token'))
        })
      },
      clearToken: () => {
        localStorage.removeItem('token')
        setToken(localStorage.getItem('token'))
      },
      setLocale: (locale) => {
        localStorage.setItem('locale', locale)
        setLocale(locale)
      },
      resetPassword: (username, url) =>
        resetPassword(fetch, username, url, locale),
      updatePassword: (token, password) =>
        updatePassword(fetch, token, password),
      T: ({ t, vars }) => trans(t, vars),
    }

    return (
      <GlobalContext.Provider value={ctx}>
        <Layout theme={theme} t={t} />
        {props.children}
      </GlobalContext.Provider>
    )
  }

  GlobalStateComponent.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  }

  return GlobalStateComponent
}

export default withGlobal(fetch)

export { GlobalContext }
