import React from 'react'
import PropTypes from 'prop-types'

const StatusBar = ({ membership, t }) => {
  if (!membership) return null
  const name = (profile, number) => {
    if (!profile) return null
    return `${profile.first_name} ${profile.last_name} - ${number}`
  }

  let status = membership.status
  if (status === 'active') {
    status = membership.scheduled_freeze ? 'active_with_scheduled_freeze' : status
  }

  return (
    <div className={`status-bar ${membership.status}`}>
      <div className={'info'}>
        { t(`status.${status}`) }
      </div>
      <span className={'name'}>
        { name(membership.profile, membership.membership_number) }
      </span>
    </div>
  )
}

StatusBar.propTypes = {
  membership: PropTypes.object,
  t: PropTypes.func.isRequired,
}

export default StatusBar
