export default [
  { prefix: '+1', whitelisted: true, validator: '^[1-9][0-9]{9}$', length: '10' },
  { prefix: '+20', whitelisted: false, validator: '^[1][0-9]{9}$', length: '10' },
  { prefix: '+211', whitelisted: false, validator: '^[9][0-9]{8}$', length: '9' },
  { prefix: '+212', whitelisted: false, validator: '^[6][0-9]{8}$', length: '9' },
  { prefix: '+213', whitelisted: false, validator: '^[5-7][0-9]{8}$', length: '9' },
  { prefix: '+216', whitelisted: false, validator: '^[259][0-9]{7}$', length: '8' },
  { prefix: '+218', whitelisted: false, validator: '^[9][0-9]{6,11}$', length: '9' },
  { prefix: '+220', whitelisted: false, validator: '^[7-9][0-9]{6}$', length: '7' },
  { prefix: '+221', whitelisted: false, validator: '^[7][0-9]{8}$', length: '9' },
  { prefix: '+222', whitelisted: false, validator: '^[2-4][0-9]{7}$', length: '8' },
  { prefix: '+223', whitelisted: false, validator: '^[67][0-9]{7}$', length: '8' },
  { prefix: '+224', whitelisted: false, validator: '^[6][0-9]{8}$', length: '9' },
  { prefix: '+225', whitelisted: false, validator: '^0[157][0-9]{8}$', length: '10' },
  { prefix: '+226', whitelisted: false, validator: '^0[67][0-9]{7}$', length: '9' },
  { prefix: '+227', whitelisted: false, validator: '^[9][0-9]{7}$', length: '8' },
  { prefix: '+228', whitelisted: false, validator: '^[79][0-9]{7}$', length: '8' },
  { prefix: '+229', whitelisted: false, validator: '^[0-9]{10}$', length: '10' },
  { prefix: '+230', whitelisted: false, validator: '^[57][0-9]{7}$', length: '8' },
  { prefix: '+231', whitelisted: false, validator: '^(77|88)[0-9]{7}$', length: '9' },
  { prefix: '+232', whitelisted: false, validator: '^[236-9][0-9]{7}$', length: '8' },
  { prefix: '+233', whitelisted: false, validator: '^[25][0-9]{8}$', length: '9' },
  { prefix: '+234', whitelisted: false, validator: '^[7-9][0-9]{9}$', length: '10' },
  { prefix: '+235', whitelisted: false, validator: '^[679][0-9]{7}$', length: '8' },
  { prefix: '+236', whitelisted: false, validator: '^[7][0-9]{7}$', length: '8' },
  { prefix: '+237', whitelisted: false, validator: '^[6][0-9]{8}$', length: '9' },
  { prefix: '+238', whitelisted: false, validator: '^[59][0-9]{6}$', length: '7' },
  { prefix: '+239', whitelisted: false, validator: '^[9][0-9]{6}$', length: '7' },
  { prefix: '+240', whitelisted: false, validator: '^[25][0-9]{8}$', length: '9' },
  { prefix: '+241', whitelisted: false, validator: '^[0-9]{8}$', length: '8' },
  { prefix: '+242', whitelisted: false, validator: '^0[67][0-9]{7}$', length: '9' },
  { prefix: '+243', whitelisted: false, validator: '^[89][0-9]{8}$', length: '9' },
  { prefix: '+244', whitelisted: false, validator: '^[9][0-9]{8}$', length: '9' },
  { prefix: '+245', whitelisted: false, validator: '^[9][0-9]{8}$', length: '9' },
  { prefix: '+246', whitelisted: false, validator: '^38[0-9]{5}$', length: '7' },
  { prefix: '+247', whitelisted: false, validator: '^[4][0-9]{4}$', length: '5' },
  { prefix: '+248', whitelisted: false, validator: '^2[57][0-9]{5}$', length: '7' },
  { prefix: '+249', whitelisted: false, validator: '^[9][0-9]{8}$', length: '9' },
  { prefix: '+250', whitelisted: false, validator: '^[7][0-9]{8}$', length: '9' },
  { prefix: '+251', whitelisted: false, validator: '^91[0-9]{7}$', length: '9' },
  { prefix: '+252', whitelisted: false, validator: '^[679][0-9]{8}$', length: '9' },
  { prefix: '+253', whitelisted: false, validator: '^77[6-8][0-9]{5}$', length: '8' },
  { prefix: '+254', whitelisted: false, validator: '^[17][0-9]{8}$', length: '9' },
  { prefix: '+255', whitelisted: false, validator: '^[67][0-9]{8}$', length: '9' },
  { prefix: '+256', whitelisted: false, validator: '^[7][0-9]{8}$', length: '9' },
  { prefix: '+257', whitelisted: false, validator: '^[67][0-9]{7}$', length: '8' },
  { prefix: '+258', whitelisted: false, validator: '^[8][0-9]{7,8}$', length: '8-9' },
  { prefix: '+260', whitelisted: false, validator: '^[9][0-9]{8}$', length: '9' },
  { prefix: '+261', whitelisted: false, validator: '^[3][0-9]{6}$', length: '7' },
  { prefix: '+262', whitelisted: false, validator: '^6(92|93|39)[0-9]{6}$', length: '9' },
  { prefix: '+263', whitelisted: false, validator: '^[7][0-9]{8}$', length: '9' },
  { prefix: '+264', whitelisted: false, validator: '^[68][0-9]{8}$', length: '9' },
  { prefix: '+265', whitelisted: false, validator: '^[89][0-9]{8}$', length: '9' },
  { prefix: '+266', whitelisted: false, validator: '^[56][0-9]{7}$', length: '8' },
  { prefix: '+267', whitelisted: false, validator: '^[7][0-9]{6}$', length: '7' },
  { prefix: '+268', whitelisted: false, validator: '^[7][0-9]{7}$', length: '8' },
  { prefix: '+269', whitelisted: false, validator: '^[3][0-9]{6}$', length: '7' },
  { prefix: '+27', whitelisted: false, validator: '^[6-8][0-9]{8}$', length: '9' },
  { prefix: '+290', whitelisted: false, validator: '^[56][0-9]{4}$', length: '5' },
  { prefix: '+291', whitelisted: false, validator: '^[7][0-9]{6}$', length: '7' },
  { prefix: '+297', whitelisted: false, validator: '^[5-9][0-9]{6}$', length: '7' },
  { prefix: '+298', whitelisted: true, validator: '^[2-9][0-9]{5}$', length: '6' },
  { prefix: '+299', whitelisted: true, validator: '^[245][0-9]{5}$', length: '6' },
  { prefix: '+30', whitelisted: true, validator: '^[6][0-9]{9}$', length: '10' },
  { prefix: '+31', whitelisted: true, validator: '^[6][0-9]{8}$', length: '9' },
  { prefix: '+32', whitelisted: true, validator: '^[4][0-9]{8}$', length: '9' },
  { prefix: '+33', whitelisted: true, validator: '^[67][0-9]{8}$', length: '9' },
  { prefix: '+34', whitelisted: true, validator: '^[67][0-9]{8}$', length: '9' },
  { prefix: '+350', whitelisted: true, validator: '^[56][0-9]{7}$', length: '8' },
  { prefix: '+351', whitelisted: true, validator: '^[9][0-9]{8}$', length: '9' },
  { prefix: '+352', whitelisted: true, validator: '^[6][0-9]{8}$', length: '9' },
  { prefix: '+353', whitelisted: true, validator: '^[8][0-9]{8}$', length: '9' },
  { prefix: '+354', whitelisted: true, validator: '^[6-8][0-9]{6}$', length: '7' },
  { prefix: '+355', whitelisted: true, validator: '^[6][0-9]{7}$', length: '8' },
  { prefix: '+356', whitelisted: true, validator: '^[79][0-9]{7}$', length: '8' },
  { prefix: '+357', whitelisted: true, validator: '^[9][0-9]{7}$', length: '8' },
  { prefix: '+358', whitelisted: true, validator: '^[45][0-9]{7,9}$', length: '8-10' },
  { prefix: '+359', whitelisted: true, validator: '^[89][0-9]{8}$', length: '9' },
  { prefix: '+36', whitelisted: true, validator: '^(20|30|31|50|70)[0-9]{8}$', length: '9' },
  { prefix: '+370', whitelisted: true, validator: '^[6][0-9]{7}$', length: '8' },
  { prefix: '+371', whitelisted: true, validator: '^[2][0-9]{7}$', length: '8' },
  { prefix: '+372', whitelisted: true, validator: '^[58][0-9]{6,7}$', length: '7-8' },
  { prefix: '+373', whitelisted: true, validator: '^[67][0-9]{7}$', length: '8' },
  { prefix: '+374', whitelisted: true, validator: '^[3-9][0-9]{7}$', length: '8' },
  { prefix: '+375', whitelisted: true, validator: '^[2-4][0-9]{8}$', length: '9' },
  { prefix: '+376', whitelisted: true, validator: '^[36][0-9]{5}$', length: '6' },
  { prefix: '+377', whitelisted: true, validator: '^[6][0-9]{7}$', length: '8' },
  { prefix: '+378', whitelisted: true, validator: '^[6][0-9]{5}$', length: '6' },
  { prefix: '+380', whitelisted: true, validator: '^[5-9][0-9]{8}$', length: '9' },
  { prefix: '+381', whitelisted: true, validator: '^[6][0-9]{8}$', length: '9' },
  { prefix: '+382', whitelisted: true, validator: '^[6][0-9]{7}$', length: '8' },
  { prefix: '+383', whitelisted: true, validator: '^[4][0-9]{7}$', length: '8' },
  { prefix: '+385', whitelisted: true, validator: '^[9][0-9]{8}$', length: '9' },
  { prefix: '+386', whitelisted: true, validator: '^[3-7][0-9]{8}$', length: '9' },
  { prefix: '+387', whitelisted: true, validator: '^[6][0-9]{8}$', length: '9' },
  { prefix: '+389', whitelisted: true, validator: '^[7][0-9]{7}$', length: '8' },
  { prefix: '+39', whitelisted: true, validator: '^[3][0-9]{8,9}$', length: '9-10' },
  { prefix: '+40', whitelisted: true, validator: '^[7][0-9]{8}$', length: '9' },
  { prefix: '+420', whitelisted: true, validator: '^[67][0-9]{8}$', length: '9' },
  { prefix: '+421', whitelisted: true, validator: '^[9][0-9]{8}$', length: '9' },
  { prefix: '+423', whitelisted: true, validator: '^[7][0-9]{6}$', length: '7' },
  { prefix: '+43', whitelisted: true, validator: '^[6][5-9][0-9]{4,10}$', length: '6-12' },
  { prefix: '+41', whitelisted: true, validator: '^[7][0-9]{8}$', length: '9' },
  { prefix: '+49', whitelisted: true, validator: '^1[5-7][0-9]{8,9}$', length: '10-11' },
  { prefix: '+44', whitelisted: true, validator: '^[7][0-9]{9}$', length: '10' },
  { prefix: '+45', whitelisted: true, validator: '^[2-9][0-9]{7}$', length: '8' },
  { prefix: '+46', whitelisted: true, validator: '^[7][0-9]{8}$', length: '9' },
  { prefix: '+47', whitelisted: true, validator: '^[459][0-9]{7}$', length: '8' },
  { prefix: '+48', whitelisted: true, validator: '^[4-8][0-9]{8}$', length: '9' },
  { prefix: '+500', whitelisted: false, validator: '^[5][0-9]{4}$', length: '5' },
  { prefix: '+501', whitelisted: false, validator: '^[6][0-9]{6}$', length: '7' },
  { prefix: '+502', whitelisted: false, validator: '^[3-5][0-9]{7}$', length: '8' },
  { prefix: '+503', whitelisted: false, validator: '^[267][0-9]{7}$', length: '8' },
  { prefix: '+504', whitelisted: false, validator: '^[37-9][0-9]{7}$', length: '8' },
  { prefix: '+505', whitelisted: false, validator: '^[578][0-9]{7}$', length: '8' },
  { prefix: '+506', whitelisted: false, validator: '^[8][0-9]{7}$', length: '8' },
  { prefix: '+507', whitelisted: false, validator: '^[6][0-9]{7}$', length: '8' },
  { prefix: '+508', whitelisted: false, validator: '^55[0-9]{4}$', length: '6' },
  { prefix: '+509', whitelisted: false, validator: '^[34][0-9]{6}$', length: '7' },
  { prefix: '+51', whitelisted: false, validator: '^[9][0-9]{8}$', length: '9' },
  { prefix: '+52', whitelisted: true, validator: '^1[2-9][0-9]{9}$', length: '11' },
  { prefix: '+53', whitelisted: false, validator: '^5[0-9]{7}$', length: '8' },
  { prefix: '+54', whitelisted: false, validator: '^9[1-9][0-9]{9}$', length: '11' },
  { prefix: '+55', whitelisted: true, validator: '^[1-9]{2}9[0-9]{8}$', length: '11' },
  { prefix: '+56', whitelisted: false, validator: '^9[0-9]{8}$', length: '9' },
  { prefix: '+57', whitelisted: false, validator: '^3[0-9]{9}$', length: '10' },
  { prefix: '+58', whitelisted: false, validator: '^4[0-9]{9}$', length: '10' },
  { prefix: '+590', whitelisted: false, validator: '^69[01][0-9]{6}$', length: '9' },
  { prefix: '+591', whitelisted: false, validator: '^[67][0-9]{7}$', length: '8' },
  { prefix: '+592', whitelisted: false, validator: '^[67][0-9]{6}$', length: '7' },
  { prefix: '+593', whitelisted: false, validator: '^9[2-9][0-9]{7}$', length: '9' },
  { prefix: '+594', whitelisted: false, validator: '^694[0-9]{6}$', length: '9' },
  { prefix: '+595', whitelisted: false, validator: '^9[1-9][0-9]{7}$', length: '9' },
  { prefix: '+596', whitelisted: false, validator: '^69[67][0-9]{6}$', length: '9' },
  { prefix: '+597', whitelisted: false, validator: '^[78][0-9]{6}$', length: '7' },
  { prefix: '+598', whitelisted: false, validator: '^9[1-9][0-9]{6}$', length: '8' },
  { prefix: '+599', whitelisted: false, validator: '^95[0-9]{5}$', length: '7' },
  { prefix: '+60', whitelisted: false, validator: '^1[0-9]{9}$', length: '10' },
  { prefix: '+61', whitelisted: true, validator: '^4[0-9]{8}$', length: '9' },
  { prefix: '+62', whitelisted: false, validator: '^8[0-9]{9}$', length: '10' },
  { prefix: '+63', whitelisted: false, validator: '^9[0-9]{9}$', length: '10' },
  { prefix: '+64', whitelisted: true, validator: '^2[1-9][0-9]{7,8}$', length: '9-10' },
  { prefix: '+65', whitelisted: false, validator: '^[89][0-9]{6}$', length: '8' },
  { prefix: '+66', whitelisted: false, validator: '^[689][0-9]{8}$', length: '9' },
  { prefix: '+670', whitelisted: false, validator: '^7[2-9][0-9]{5}$', length: '7' },
  { prefix: '+672', whitelisted: false, validator: '^[1-9][0-9]{6,11}$', length: '7-12' },
  { prefix: '+673', whitelisted: false, validator: '^[789][0-9]{6,7}$', length: '7-8' },
  { prefix: '+674', whitelisted: false, validator: '^(55|66|88)[0-9]{5}$', length: '7' },
  { prefix: '+675', whitelisted: false, validator: '^[78][0-9]{6}$', length: '7' },
  { prefix: '+676', whitelisted: false, validator: '^7[0-9]{4}$', length: '5' },
  { prefix: '+677', whitelisted: false, validator: '^[7-9][0-9]{6}$', length: '7' },
  { prefix: '+678', whitelisted: false, validator: '^[57][0-9]{6}$', length: '7' },
  { prefix: '+679', whitelisted: false, validator: '^[7-9][0-9]{6}$', length: '7' },
  { prefix: '+680', whitelisted: false, validator: '^488[0-9]{4}$', length: '7' },
  { prefix: '+681', whitelisted: false, validator: '^8[23][0-9]{4}$', length: '6' },
  { prefix: '+682', whitelisted: false, validator: '^[578][0-9]{4}$', length: '5' },
  { prefix: '+683', whitelisted: false, validator: '^5[0-9]{3}$', length: '4' },
  { prefix: '+685', whitelisted: false, validator: '^[78][0-9]{6}$', length: '7' },
  { prefix: '+686', whitelisted: false, validator: '^6[0-9]{6}$', length: '7' },
  { prefix: '+687', whitelisted: false, validator: '^[0-9]{6}$', length: '6' },
  { prefix: '+688', whitelisted: false, validator: '^7[0-9]{4}$', length: '5' },
  { prefix: '+689', whitelisted: false, validator: '^8[79][0-9]{6}$', length: '8' },
  { prefix: '+690', whitelisted: false, validator: '^7[2-7][0-9]{3,6}$', length: '5-8' },
  { prefix: '+691', whitelisted: false, validator: '^7[0-9]{7}$', length: '8' },
  { prefix: '+692', whitelisted: false, validator: '^3[0-9]{6}$', length: '7' },
  { prefix: '+7', whitelisted: false, validator: '^9[0-9]{9}$', length: '10' },
  { prefix: '+81', whitelisted: false, validator: '^[7-9]0[0-9]{8}$', length: '10' },
  { prefix: '+82', whitelisted: false, validator: '^10[0-9]{8}$', length: '10' },
  { prefix: '+84', whitelisted: false, validator: '^[35789][0-9]{9}$', length: '10' },
  { prefix: '+850', whitelisted: false, validator: '^19[0-9]{7}$', length: '9' },
  { prefix: '+852', whitelisted: false, validator: '^[4-9][0-9]{7}$', length: '8' },
  { prefix: '+853', whitelisted: false, validator: '^6[0-9]{7}$', length: '8' },
  { prefix: '+855', whitelisted: false, validator: '^[1-9][0-9]{7,8}$', length: '8-9' },
  { prefix: '+856', whitelisted: false, validator: '^20[2-9][0-9]{6,7}$', length: '9-10' },
  { prefix: '+86', whitelisted: false, validator: '^1[0-9]{10}$', length: '11' },
  { prefix: '+880', whitelisted: false, validator: '^1[3-9][0-9]{8}$', length: '10' },
  { prefix: '+886', whitelisted: false, validator: '^9[0-9]{8}$', length: '9' },
  { prefix: '+90', whitelisted: false, validator: '^5[0-9]{9}$', length: '10' },
  { prefix: '+91', whitelisted: false, validator: '^[1-9][0-9]{9}$', length: '10' },
  { prefix: '+92', whitelisted: false, validator: '^3[0-9]{9}$', length: '10' },
  { prefix: '+93', whitelisted: false, validator: '^7[0-9]{8}$', length: '9' },
  { prefix: '+94', whitelisted: false, validator: '^7[0-9]{8}$', length: '9' },
  { prefix: '+95', whitelisted: false, validator: '^9[0-9]{8}$', length: '9' },
  { prefix: '+960', whitelisted: false, validator: '^[79][0-9]{6}$', length: '7' },
  { prefix: '+961', whitelisted: false, validator: '^[378][0-9]{6}$', length: '7' },
  { prefix: '+962', whitelisted: false, validator: '^7[7-9][0-9]{7}$', length: '9' },
  { prefix: '+963', whitelisted: false, validator: '^9[1-9][0-9]{7}$', length: '9' },
  { prefix: '+964', whitelisted: false, validator: '^7[5-9][0-9]{8}$', length: '10' },
  { prefix: '+965', whitelisted: false, validator: '^[569][0-9]{7}$', length: '8' },
  { prefix: '+966', whitelisted: false, validator: '^5[0-9]{8}$', length: '9' },
  { prefix: '+967', whitelisted: false, validator: '^[1378][0-9]{7}$', length: '8' },
  { prefix: '+968', whitelisted: false, validator: '^9[0-9]{7}$', length: '8' },
  { prefix: '+970', whitelisted: false, validator: '^5[69][0-9]{7}$', length: '9' },
  { prefix: '+971', whitelisted: false, validator: '^5[0-9]{8}$', length: '9' },
  { prefix: '+972', whitelisted: false, validator: '^5[0-9]{8}$', length: '9' },
  { prefix: '+973', whitelisted: false, validator: '^[36][0-9]{7}$', length: '8' },
  { prefix: '+974', whitelisted: false, validator: '^[3567][0-9]{7}$', length: '8' },
  { prefix: '+975', whitelisted: false, validator: '^[17]7[0-9]{6}$', length: '8' },
  { prefix: '+976', whitelisted: false, validator: '^[7-9][0-9]{7}$', length: '8' },
  { prefix: '+977', whitelisted: false, validator: '^98[4-9][0-9]{7}$', length: '10' },
  { prefix: '+98', whitelisted: false, validator: '^9[0-9]{9}$', length: '10' },
  { prefix: '+992', whitelisted: false, validator: '^(9[1-5]|50|55)[0-9]{7}$', length: '9' },
  { prefix: '+993', whitelisted: false, validator: '^(6[0-9]|71)[0-9]{6}$', length: '8' },
  { prefix: '+994', whitelisted: false, validator: '^(50|51|55|70|77|99)[0-9]{7}$', length: '9' },
  { prefix: '+995', whitelisted: false, validator: '^5[0-9]{8}$', length: '9' },
  { prefix: '+996', whitelisted: false, validator: '^7[0-9]{8}$', length: '9' },
  { prefix: '+997', whitelisted: false, validator: '^7[07][0-9]{8}$', length: '10' },
  { prefix: '+998', whitelisted: false, validator: '^(20|33|50|77|88|9[0-9])[0-9]{7}$', length: '9' },
]
