import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom'
import Navigation from './components/Navigation'
import TopBar from './components/TopBar'
import { GlobalContext, UserContext } from 'components/context'
import {
  Contact,
  IndexInfo,
  Membership,
  Payment,
  Referral,
} from './Pages'
import {
  PTBooking,
  PTPackage,
} from './Pages/PT'
import StatusBar from './components/StatusBar'
import BreadCrumbs from './components/BreadCrumbs'
import { statusIsInactive } from '../../helpers'

const RedirectComponent = () =>
  <Redirect to={'/'} />

const RoutesForActiveMembership = ({ membership }) => {
  if (!membership) return null

  const inactive = statusIsInactive(membership.status)

  return (
    <>
      <Route path={'/referral'}>
        <Route component={BreadCrumbs} />
        <Route component={inactive ? RedirectComponent : Referral} />
      </Route>
    </>
  )
}

RoutesForActiveMembership.propTypes = {
  membership: PropTypes.object,
}

const MyPage = ({ className }) => {
  const { clearToken, t, theme } = React.useContext(GlobalContext)
  const { get, membership, setMembershipState } = React.useContext(UserContext)
  const [unpaid, setUnpaid] = React.useState(false)
  const alerts = []

  React.useEffect(() => {
    if (membership) return
    console.debug('fetching membership')
    get('membership').then(res => setMembershipState(res))
    get('invoices').then(res => {
      setUnpaid(res.some((invoice) => (invoice.status === 'declined' && invoice.period)))
    })
  })

  if (unpaid) {
    alerts.push('payment')
  }

  return (
    <Router>
      <div className={className}>
        <TopBar {...{ clearToken, t, theme }} />
        <div className={'main'}>
          <Route
            path={'*'}
            render={p => <Navigation t={t} hiddenMobile={p.location.pathname !== '/'} alerts={alerts} {...p} />}
          />
          <StatusBar t={t} membership={membership} />
          <div className={'content'}>
            <Route path={'/'} exact component={IndexInfo} />
            <Route path={'/offers'} component={PTPackage} />

            <Route path={'/contact'}>
              <Route component={BreadCrumbs} />
              <Route path={'/contact'} component={Contact} />
            </Route>

            <Route path={'/payment'}>
              <Route component={BreadCrumbs} />
              <Route path={'/payment'} component={Payment} />
            </Route>

            <Route path={'/pt'}>
              <Route component={BreadCrumbs} />
              <Route path={'/pt'} component={PTBooking} />
            </Route>

            <Route path={'/membership'}>
              <Route component={BreadCrumbs} />
              <Membership membership={membership} />
            </Route>
            <RoutesForActiveMembership membership={membership} />
          </div>
        </div>
      </div>
    </Router>
  )
}

MyPage.propTypes = {
  className: PropTypes.string,
}

export default MyPage
