import i18n from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'

// This is just a simple implementation
// without any pulling from phraseapp
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    interpolation: { escapeValue: false },
    resources: {
      'de-AT': {
        translation: require('./locales/de-AT/translation.json'),
      },
      'de-CH': {
        translation: require('./locales/de-CH/translation.json'),
      },
      'de-DE': {
        translation: require('./locales/de-DE/translation.json'),
      },
      'en-AT': {
        translation: require('./locales/en-AT/translation.json'),
      },
      'en-CH': {
        translation: require('./locales/en-CH/translation.json'),
      },
      'en-DE': {
        translation: require('./locales/en-DE/translation.json'),
      },
      'fi-FI': {
        translation: require('./locales/fi-FI/translation.json'),
      },
      fi: {
        translation: require('./locales/fi-FI/translation.json'),
      },
      'fr-CH': {
        translation: require('./locales/fr-CH/translation.json'),
      },
      'nb-NO': {
        translation: require('./locales/nb-NO/translation.json'),
      },
      nb: {
        translation: require('./locales/nb-NO/translation.json'),
      },
      'en-NO': {
        translation: require('./locales/en-NO/translation.json'),
      },
      // Default
      en: {
        translation: require('./locales/en-DEV/translation.json'),
      },
    },
    lng: localStorage.getItem('locale') || 'en',
    fallbackLng: 'en',
    detection: ['localStorage', 'querystring'],
  })

export {
  useTranslation,
}

export default i18n
